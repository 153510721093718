import { Col, Row } from 'antd';
import Link from 'next/link';
import { TwitterIcon } from 'react-share';
import styled, { css } from 'styled-components';

import Facebook from '../../assets/icons/Facebook';
import Linkedin from '../../assets/icons/Linkedin';
import Logo from '../../assets/icons/Logo';
import BGroundSvg from '../../assets/images/LandingPage/bground.svg';
import theme from '../../assets/theme';
import { useBreakPoint } from '../../hooks/useBreakPoint';
import { useIsMobile } from '../../hooks/useIsMobile';
import { IconWrapper } from '../_atoms/IconWrapper';

const LandingPageFooter = ({
  footerBackgroundColor,
}: {
  footerBackgroundColor?: string;
}) => {
  const ismobile = useIsMobile(); // ? 'true' : undefined;
  return (
    <Container $footerBackgroundColor={footerBackgroundColor}>
      <Row
        gutter={[0, 32]}
        style={{
          paddingTop: '80px',
          paddingBottom: '80px',
          width: '100%',
          maxWidth: '1128px',
          marginBottom: 0,
          marginLeft: 'auto',
          marginRight: 'auto',
          color: theme.colors.white,
          position: 'relative',
        }}
        justify={ismobile ? 'start' : 'space-between'}
      >
        <MadeWithLoveContainer>
          <span style={{ fontWeight: 'bold' }}>
            Made with{' '}
            <span role="img" aria-label="Love">
              ❤️
            </span>{' '}
            from Norway
          </span>
        </MadeWithLoveContainer>
        <Col xs={12} md={5} style={{}}>
          <Link href={'/'} passHref prefetch={false}>
            <a>
              <IconWrapper
                color={theme.colors.white}
                height={'22px'}
                width={'81px'}
                style={{ justifyContent: 'flex-start', display: 'flex' }}
              >
                <Logo />
              </IconWrapper>
            </a>
          </Link>
          <p style={{ color: theme.colors.white, fontWeight: 500 }}>© 2024 Curipod AS</p>
          <SocialContainer>
            <span style={{ color: theme.colors.white, fontWeight: 500 }}>Follow us</span>
            <Link
              href="https://twitter.com/curipodofficial"
              passHref
              aria-label="Twitter link"
              prefetch={false}
            >
              <a target="__blank" rel="noopener noreferrer">
                <TwitterIcon style={{ borderRadius: 50 }} />
              </a>
            </Link>
            <Link
              href="https://www.facebook.com/curipod"
              passHref
              aria-label="Facebook link"
              prefetch={false}
            >
              <a target="__blank" rel="noopener noreferrer">
                <Facebook />
              </a>
            </Link>
            <Link
              href="https://www.linkedin.com/company/curipod/"
              passHref
              aria-label="Linkedin link"
              prefetch={false}
            >
              <a target="__blank" rel="noopener noreferrer">
                <Linkedin />
              </a>
            </Link>
          </SocialContainer>
        </Col>
        <Col xs={12} md={4} style={{}}>
          <h3 style={{ color: theme.colors.white, fontWeight: 'bold' }}>Company</h3>
          <Link href="/about-us" passHref>
            <a>
              <StyledLink style={{ display: 'block' }}>About us</StyledLink>
            </a>
          </Link>
          <Link href="/blog" passHref>
            <a>
              <StyledLink style={{ display: 'block' }}>Blog</StyledLink>
            </a>
          </Link>
          <Link href="/contact-us" passHref prefetch={false}>
            <a>
              <StyledLink style={{ display: 'block' }}>Contact us</StyledLink>
            </a>
          </Link>
          <Link href="/pricing" passHref>
            <a>
              <StyledLink style={{ display: 'block' }}>Pricing</StyledLink>
            </a>
          </Link>
          <Link
            href="https://curipod.notion.site/The-Curipod-work-Experience-9a732ddf5a904f6abac84d55a733fc02?pvs=4"
            passHref
            prefetch={false}
          >
            <a target="__blank" rel="noopener noreferrer">
              <StyledLink style={{ display: 'block' }}>We&apos;re hiring</StyledLink>
            </a>
          </Link>
          <Link href="/learning-principles" passHref prefetch={false}>
            <a>
              <StyledLink style={{ display: 'block' }}>
                The Curipod learning principles
              </StyledLink>
            </a>
          </Link>
          <Link href="https://shop.curipod.com/" passHref prefetch={false}>
            <a target="__blank" rel="noopener noreferrer">
              <StyledLink style={{ display: 'block' }}>Merchandise store</StyledLink>
            </a>
          </Link>
        </Col>
        <Col xs={12} md={4} style={{}}>
          <h3 style={{ color: theme.colors.white, fontWeight: 'bold' }}>Community</h3>
          <Link href="/community" passHref>
            <a>
              <StyledLink style={{ display: 'block' }}>Join our community</StyledLink>
            </a>
          </Link>
          <Link href="/ai-certification" passHref>
            <a>
              <StyledLink style={{ display: 'block' }}>Ai certificate</StyledLink>
            </a>
          </Link>
          <Link href="/c/ambassadors" passHref>
            <a>
              <StyledLink style={{ display: 'block' }}>Ambassador program</StyledLink>
            </a>
          </Link>
        </Col>
        <Col xs={12} md={4} style={{}}>
          <h3 style={{ color: theme.colors.white, fontWeight: 'bold' }}>Legal</h3>
          <Link href="/privacy-policy" passHref prefetch={false}>
            <a>
              <StyledLink style={{ display: 'block' }}>Privacy policy</StyledLink>
            </a>
          </Link>
          <Link href="/state-and-district-privacy-agreements" passHref prefetch={false}>
            <a>
              <StyledLink style={{ display: 'block' }}>
                State and District Privacy Agreements
              </StyledLink>
            </a>
          </Link>
          <Link href="/cookie-policy" passHref prefetch={false}>
            <a>
              <StyledLink style={{ display: 'block' }}>Cookie policy</StyledLink>
            </a>
          </Link>
          <Link href="/ai-in-curipod" passHref prefetch={false}>
            <a>
              <StyledLink style={{ display: 'block' }}>AI in Curipod</StyledLink>
            </a>
          </Link>

          <Link href="/terms-of-use" passHref prefetch={false}>
            <a>
              <StyledLink style={{ display: 'block' }}>Terms of use</StyledLink>
            </a>
          </Link>
          <Link href="/gdpr" passHref prefetch={false}>
            <a>
              <StyledLink style={{ display: 'block' }}>
                GDPR{' '}
                <span role="img" aria-label="Love">
                  ❤️
                </span>{' '}
              </StyledLink>
            </a>
          </Link>
        </Col>

        <Col xs={12} md={4} style={{}}>
          <h3 style={{ color: theme.colors.white, fontWeight: 'bold' }}>Resources</h3>
          <Link href={`https://curipod.com/Curipod_Case_Study_South_Texas.pdf`} passHref>
            <a>
              <StyledLink style={{ display: 'block' }}>Case study</StyledLink>
            </a>
          </Link>
          <Link href={`/c/courses`} passHref>
            <a>
              <StyledLink style={{ display: 'block' }}>Training</StyledLink>
            </a>
          </Link>
          <Link href="/c/staar-test-prep" passHref>
            <a>
              <StyledLink style={{ display: 'block' }}>STAAR test prep</StyledLink>
            </a>
          </Link>
          <Link href="/c/caaspp-test-prep" passHref>
            <a>
              <StyledLink style={{ display: 'block' }}>CAASPP test prep</StyledLink>
            </a>
          </Link>
          <Link href="/c/gmas-test-prep" passHref>
            <a>
              <StyledLink style={{ display: 'block' }}>GMAS test prep</StyledLink>
            </a>
          </Link>
          <Link href="/c/argumentative-writing" passHref>
            <a>
              <StyledLink style={{ display: 'block' }}>Argumentative writing</StyledLink>
            </a>
          </Link>
          <Link href="/c/narrative-writing" passHref>
            <a>
              <StyledLink style={{ display: 'block' }}>Narrative writing</StyledLink>
            </a>
          </Link>
          <Link href="/customizable-lessons" passHref>
            <a>
              <StyledLink style={{ display: 'block' }}>
                All customizable lessons
              </StyledLink>
            </a>
          </Link>
          <Link href="/c/how-to-moderate-inappropriate-students-in-curipod" passHref>
            <a>
              <StyledLink style={{ display: 'block' }}>Moderation tool</StyledLink>
            </a>
          </Link>
          <Link href="/business" passHref>
            <a>
              <StyledLink style={{ display: 'block' }}>For business</StyledLink>
            </a>
          </Link>
          <Link href="/accessibility" passHref>
            <a>
              <StyledLink style={{ display: 'block' }}>Accessibility</StyledLink>
            </a>
          </Link>
          <Link href="/connectivity-issues" passHref>
            <a>
              <StyledLink style={{ display: 'block' }}>Connectivity issues</StyledLink>
            </a>
          </Link>
          <Link
            href="https://www.canva.com/design/DAFuuziK1yM/SWx7Y1ia8p9cefjFzoD2bg/view?utm_content=DAFuuziK1yM&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink#16"
            passHref
          >
            <a target="__blank" rel="noopener noreferrer">
              <StyledLink style={{ display: 'block' }}>CuriCon 2023 - replays</StyledLink>
            </a>
          </Link>
          <Link href="https://wakelet.com/wake/_Gp7EAdlbjvOmNAyX7m5w" passHref>
            <a target="__blank" rel="noopener noreferrer">
              <StyledLink style={{ display: 'block' }}>PD resources</StyledLink>
            </a>
          </Link>
        </Col>
      </Row>
    </Container>
  );
};
export const NewLandingPageFooter = ({
  footerBackgroundColor,
}: {
  footerBackgroundColor?: string;
}) => {
  const ismobile = useIsMobile(); // ? 'true' : undefined;
  const isLargerThan800 = useBreakPoint(800);
  return (
    <Container
      $footerBackgroundColor={footerBackgroundColor}
      style={{ marginBottom: !isLargerThan800 ? '60px' : '' }}
    >
      <Row
        gutter={[0, 32]}
        style={{
          paddingTop: '80px',
          paddingBottom: '80px',
          width: '100%',
          maxWidth: '1128px',
          marginBottom: 0,
          marginLeft: 'auto',
          marginRight: 'auto',
          color: theme.colors.black,
          position: 'relative',
        }}
        justify={ismobile ? 'start' : 'center'}
      >
        <MadeWithLoveContainer>
          <span style={{ fontWeight: 'bold' }}>
            Made with{' '}
            <span role="img" aria-label="Love">
              🧡
            </span>{' '}
            from Norway
          </span>
        </MadeWithLoveContainer>
        <Col xs={12} md={5} style={{}}>
          <Link href={'/'} passHref prefetch={false}>
            <a>
              <IconWrapper
                color={theme.colors.orange}
                height={'22px'}
                width={'81px'}
                style={{ justifyContent: 'flex-start', display: 'flex' }}
              >
                <Logo />
              </IconWrapper>
            </a>
          </Link>
          <p style={{ color: theme.colors.black, fontWeight: 500 }}>© 2024 Curipod AS</p>
          <SocialContainer>
            <span style={{ color: theme.colors.black, fontWeight: 500 }}>Follow us</span>
            <Link
              href="https://twitter.com/curipodofficial"
              passHref
              aria-label="Twitter link"
              prefetch={false}
            >
              <a
                style={{ color: theme.colors.black }}
                target="__blank"
                rel="noopener noreferrer"
              >
                <TwitterIcon style={{ borderRadius: 50 }} />
              </a>
            </Link>
            <Link
              href="https://www.facebook.com/curipod"
              passHref
              aria-label="Facebook link"
              prefetch={false}
            >
              <a
                style={{ color: theme.colors.black }}
                target="__blank"
                rel="noopener noreferrer"
              >
                <Facebook />
              </a>
            </Link>
            <Link
              href="https://www.linkedin.com/company/curipod/"
              passHref
              aria-label="Linkedin link"
              prefetch={false}
            >
              <a
                style={{ color: theme.colors.black }}
                target="__blank"
                rel="noopener noreferrer"
              >
                <Linkedin />
              </a>
            </Link>
          </SocialContainer>
        </Col>
        <Col xs={12} md={4} style={{}}>
          <h3 style={{ color: theme.colors.black, fontWeight: 'bold' }}>Company</h3>
          <Link href="/about-us" passHref>
            <a>
              <StyledLink $color={theme.colors.black} style={{ display: 'block' }}>
                About us
              </StyledLink>
            </a>
          </Link>
          <Link href="/blog" passHref>
            <a>
              <StyledLink $color={theme.colors.black} style={{ display: 'block' }}>
                Blog
              </StyledLink>
            </a>
          </Link>
          <Link href="/contact-us" passHref prefetch={false}>
            <a>
              <StyledLink $color={theme.colors.black} style={{ display: 'block' }}>
                Contact us
              </StyledLink>
            </a>
          </Link>
          <Link href="/pricing" passHref>
            <a>
              <StyledLink $color={theme.colors.black} style={{ display: 'block' }}>
                Pricing
              </StyledLink>
            </a>
          </Link>
          <Link
            href="https://curipod.notion.site/The-Curipod-work-Experience-9a732ddf5a904f6abac84d55a733fc02?pvs=4"
            passHref
            prefetch={false}
          >
            <a target="__blank" rel="noopener noreferrer">
              <StyledLink $color={theme.colors.black} style={{ display: 'block' }}>
                We&apos;re hiring
              </StyledLink>
            </a>
          </Link>
          <Link href="/learning-principles" passHref prefetch={false}>
            <a>
              <StyledLink $color={theme.colors.black} style={{ display: 'block' }}>
                The Curipod learning principles
              </StyledLink>
            </a>
          </Link>
          <Link href="https://shop.curipod.com/" passHref prefetch={false}>
            <a target="__blank" rel="noopener noreferrer">
              <StyledLink $color={theme.colors.black} style={{ display: 'block' }}>
                Merchandise store
              </StyledLink>
            </a>
          </Link>
        </Col>
        <Col xs={12} md={4} style={{}}>
          <h3 style={{ color: theme.colors.black, fontWeight: 'bold' }}>Community</h3>
          <Link href="/community" passHref>
            <a>
              <StyledLink $color={theme.colors.black} style={{ display: 'block' }}>
                Join our community
              </StyledLink>
            </a>
          </Link>
          <Link href="/ai-certification" passHref>
            <a>
              <StyledLink $color={theme.colors.black} style={{ display: 'block' }}>
                Ai certificate
              </StyledLink>
            </a>
          </Link>
          <Link href="/c/ambassadors" passHref>
            <a>
              <StyledLink $color={theme.colors.black} style={{ display: 'block' }}>
                Ambassador program
              </StyledLink>
            </a>
          </Link>
        </Col>
        <Col xs={12} md={4} style={{}}>
          <h3 style={{ color: theme.colors.black, fontWeight: 'bold' }}>Legal</h3>
          <Link href="/privacy-policy" passHref prefetch={false}>
            <a>
              <StyledLink $color={theme.colors.black} style={{ display: 'block' }}>
                Privacy policy
              </StyledLink>
            </a>
          </Link>
          <Link href="/state-and-district-privacy-agreements" passHref prefetch={false}>
            <a>
              <StyledLink $color={theme.colors.black} style={{ display: 'block' }}>
                State and District Privacy Agreements
              </StyledLink>
            </a>
          </Link>
          <Link href="/cookie-policy" passHref prefetch={false}>
            <a>
              <StyledLink $color={theme.colors.black} style={{ display: 'block' }}>
                Cookie policy
              </StyledLink>
            </a>
          </Link>
          <Link href="/ai-in-curipod" passHref prefetch={false}>
            <a>
              <StyledLink $color={theme.colors.black} style={{ display: 'block' }}>
                AI in Curipod
              </StyledLink>
            </a>
          </Link>

          <Link href="/terms-of-use" passHref prefetch={false}>
            <a>
              <StyledLink $color={theme.colors.black} style={{ display: 'block' }}>
                Terms of use
              </StyledLink>
            </a>
          </Link>
          <Link href="/gdpr" passHref prefetch={false}>
            <a>
              <StyledLink $color={theme.colors.black} style={{ display: 'block' }}>
                GDPR{' '}
                <span role="img" aria-label="Love">
                  🧡
                </span>{' '}
              </StyledLink>
            </a>
          </Link>
        </Col>

        <Col xs={12} md={4} style={{}}>
          <h3 style={{ color: theme.colors.black, fontWeight: 'bold' }}>Resources</h3>
          <Link href={`https://curipod.com/Curipod_Case_Study_South_Texas.pdf`} passHref>
            <a>
              <StyledLink $color={theme.colors.black} style={{ display: 'block' }}>
                Case study
              </StyledLink>
            </a>
          </Link>
          <Link href={`/c/courses`} passHref>
            <a>
              <StyledLink $color={theme.colors.black} style={{ display: 'block' }}>
                Training
              </StyledLink>
            </a>
          </Link>
          <Link href="/c/staar-test-prep" passHref>
            <a>
              <StyledLink $color={theme.colors.black} style={{ display: 'block' }}>
                STAAR test prep
              </StyledLink>
            </a>
          </Link>
          <Link href="/c/argumentative-writing" passHref>
            <a>
              <StyledLink $color={theme.colors.black} style={{ display: 'block' }}>
                Argumentative writing
              </StyledLink>
            </a>
          </Link>
          <Link href="/c/narrative-writing" passHref>
            <a>
              <StyledLink $color={theme.colors.black} style={{ display: 'block' }}>
                Narrative writing
              </StyledLink>
            </a>
          </Link>

          <Link href="/business" passHref>
            <a>
              <StyledLink $color={theme.colors.black} style={{ display: 'block' }}>
                For business
              </StyledLink>
            </a>
          </Link>
          <Link href="/accessibility" passHref>
            <a>
              <StyledLink $color={theme.colors.black} style={{ display: 'block' }}>
                Accessibility
              </StyledLink>
            </a>
          </Link>
          <Link href="/customizable-lessons" passHref>
            <a>
              <StyledLink $color={theme.colors.black} style={{ display: 'block' }}>
                All customizable lessons
              </StyledLink>
            </a>
          </Link>
          <Link href="/c/how-to-moderate-inappropriate-students-in-curipod" passHref>
            <a>
              <StyledLink $color={theme.colors.black} style={{ display: 'block' }}>
                Moderation tool
              </StyledLink>
            </a>
          </Link>
          <Link href="/connectivity-issues" passHref>
            <a>
              <StyledLink $color={theme.colors.black} style={{ display: 'block' }}>
                Connectivity issues
              </StyledLink>
            </a>
          </Link>
          <Link
            href="https://www.canva.com/design/DAFuuziK1yM/SWx7Y1ia8p9cefjFzoD2bg/view?utm_content=DAFuuziK1yM&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink#16"
            passHref
          >
            <a target="__blank" rel="noopener noreferrer">
              <StyledLink $color={theme.colors.black} style={{ display: 'block' }}>
                CuriCon 2023 - replays
              </StyledLink>
            </a>
          </Link>
          <Link href="https://wakelet.com/wake/_Gp7EAdlbjvOmNAyX7m5w" passHref>
            <a target="__blank" rel="noopener noreferrer">
              <StyledLink $color={theme.colors.black} style={{ display: 'block' }}>
                PD resources
              </StyledLink>
            </a>
          </Link>
        </Col>
      </Row>
    </Container>
  );
};

const StyledLink = styled.span<{ $color?: string }>`
  color: ${({ $color }) => ($color ? $color : theme.colors.white)};
  font-weight: bold;
  :hover {
    color: ${theme.colors.black};
  }
`;

const MadeWithLoveContainer = styled.div`
  position: absolute;
  bottom: 20px;
  right: 90px;
`;

const Container = styled.div<{ $footerBackgroundColor?: string }>`
  width: 100%;

  ${({ $footerBackgroundColor }) =>
    $footerBackgroundColor
      ? css`
          background-color: ${$footerBackgroundColor};
        `
      : css`
          background-image: url(${BGroundSvg.src});
          background-size: cover;
        `}
  @media (max-width: 1128px) {
    padding: 0px 20px;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  a,
  span {
    font-family: Nunito;
  }

  p {
    font-weight: bold;
    line-height: 22px;
    font-size: 14px;
    margin-bottom: 10px;
  }
  a {
    text-decoration: unset;
    line-height: 20px;
  }
  h3 {
    font-size: 1.17em;
    line-height: 1.5715;
    margin-bottom: 0.5em;
    margin-top: 0;
  }
`;

const SocialContainer = styled.div`
  display: flex;
  align-items: center;
  & > * {
    margin-right: 5px;
  }
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
  }
  svg {
    height: 18px;
    width: 18px;
  }
`;
export default LandingPageFooter;
