import { LoadingOutlined } from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';
import { Select, Spin } from 'antd';
import { debounce } from 'lodash';
import mixpanel from 'mixpanel-browser';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntercom } from 'react-use-intercom';
import styled from 'styled-components';

import StarStruckSvg from '../assets/icons/StarStruckSvg';
import theme from '../assets/theme';
import { Input } from '../components/_atoms/_Input';
import { Button } from '../components/_atoms/Button';
import { LoadingDots } from '../components/_atoms/DismissableQuestionList/DismissableQuestionList';
import { IconWrapper } from '../components/_atoms/IconWrapper';
import BaseModal from '../components/_molecules/BaseModal';
import useMe from '../hooks/useMe';
import { CountrySelector } from '../hooks/usePaywallPrompt';
import backendService from '../services/backendService';
import { DatabaseSchool } from '../services/CreatorService/Creator.type';
import { SelectWrapper } from './signup';

const CountrySchoolSelectionPopup = () => {
  const [isModalVisible, setIsModalVisible] = useState(true);
  const [country, setCountry] = useState<string>();
  const [schoolName, setSchoolName] = useState<string>();

  const [schoolId, setSchoolId] = useState<number>();
  const [currentForm, setCurrentForm] = useState<'country' | 'selectSchool'>('country');
  const [zipCode, setZipCode] = useState('');

  const { showNewMessages } = useIntercom();

  const { data: schoolsInZip = [], isLoading: isLoadingSchoolsInZip } = useQuery({
    queryKey: ['schoolsInZip', zipCode],
    queryFn: async () => {
      if (!zipCode) return [];
      const { schools } = await backendService.instance.getSchoolsInZip(zipCode);

      return schools;
    },
  });

  const dropdownOptions = useMemo(() => {
    return schoolsInZip.map((eachSchool: DatabaseSchool) => {
      return {
        value: eachSchool.schoolId,
        label: eachSchool.schoolName,
      };
    });
  }, [schoolsInZip]);

  const { editProfile, isEditingProfile } = useMe();

  const handleSubmit = (ignoreSchoolId = false) => {
    if (schoolId && !ignoreSchoolId) {
      mixpanel.track('countrySchoolPopup.submit.with.schoolId', {
        schoolCountry: country,
        schoolId: schoolId,
      });
      // Edit profile with schoolId
      editProfile({
        editedProfile: {
          schoolCountry: country,
          ...(schoolId && { schoolId }),
        },
        onSuccess: () => {
          setIsModalVisible(false);
        },
      });
    } else {
      mixpanel.track('countrySchoolPopup.submit.without.schoolId', {
        schoolCountry: country,
        schoolName: schoolName,
      });
      // Edit profile without schoolId
      editProfile({
        editedProfile: {
          schoolCountry: country,
        },
        onSuccess: () => {
          setIsModalVisible(false);
          if (country === 'US' || country === 'United States of America') {
            showNewMessages(
              `Hey! I want to select my school, but it's not listed. 🙏\n\n▶️ School Name, Zip Code: `,
            );
          }
        },
      });
    }
  };

  const handleCantFindMySchool = () => {
    mixpanel.track('countrySchoolPopup.CouldNotFindSchool.Button.Clicked', {
      zipCode: zipCode,
    });
    handleSubmit(true);
  };

  const handleContinue = () => {
    if (currentForm === 'country' && country === 'US') {
      setCurrentForm('selectSchool');
    } else {
      handleSubmit();
    }
  };

  const searchSchools = debounce(zipCodeSearch => {
    setZipCode(zipCodeSearch);
  }, 400);

  const handleSelectionChange = useCallback(
    (schoolId: number) => {
      setSchoolId(schoolId);
      setSchoolName(
        schoolsInZip.find((s: DatabaseSchool) => s.schoolId === schoolId)?.schoolName,
      );
    },
    [setSchoolName, setSchoolId, schoolsInZip],
  );

  const getContinueButtonDisabled = () => {
    if (isEditingProfile) return true;
    if (currentForm === 'country') {
      return !country;
    } else if (currentForm === 'selectSchool') {
      return !schoolId;
    }
  };

  return (
    <BaseModal
      title={`Complete your profile`}
      visible={isModalVisible}
      maskClosable={false}
    >
      <FormContainer>
        {currentForm === 'country' && (
          <CountrySelector
            style={{ width: '300px' }}
            onChange={country => setCountry(country)}
            value={country}
            labelStyle={{ fontSize: 14 }}
          />
        )}

        {currentForm === 'selectSchool' && (
          <>
            <SchoolSelectionContainer>
              <div style={{ position: 'relative' }}>
                <Input
                  onKeyPress={event => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  maxLength={5}
                  min={0}
                  style={{ width: '100%' }}
                  placeholder="Your school's zip code"
                  onChange={e => {
                    setSchoolId(undefined);
                    searchSchools(e.target.value);
                  }}
                  value={zipCode}
                  label="Your school's zip code"
                  labelStyle={{ fontSize: 14, color: theme.colors.black }}
                />
                {isLoadingSchoolsInZip && (
                  <p style={{ width: 'max-content', position: 'absolute' }}>
                    <Spin
                      indicator={
                        <LoadingOutlined
                          style={{
                            fontSize: 15,
                            color: theme.colors.green,
                            marginRight: 5,
                          }}
                          spin
                        />
                      }
                    />
                    Searching for schools<LoadingDots>...</LoadingDots>
                  </p>
                )}
              </div>
              {zipCode && !isLoadingSchoolsInZip && schoolsInZip.length === 0 && (
                <p
                  style={{
                    width: 'fit-content',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 20,
                  }}
                >
                  Could not find any schools in this zip code. Please try again
                </p>
              )}
              {schoolsInZip.length !== 0 && !isLoadingSchoolsInZip && (
                <SelectWrapper>
                  {zipCode && !isLoadingSchoolsInZip && schoolsInZip.length > 0 && (
                    <p
                      style={{
                        width: 'fit-content',
                        display: 'flex',
                        alignItems: 'center',
                        gap: 2,
                        marginBottom: 5,
                      }}
                    >
                      <IconWrapper width="20px" height="20px">
                        <StarStruckSvg />
                      </IconWrapper>
                      Found {schoolsInZip.length} school
                      {schoolsInZip.length > 1 ? 's' : ''}
                    </p>
                  )}
                  <Select
                    placeholder="Select your school"
                    disabled={isLoadingSchoolsInZip || schoolsInZip.length === 0}
                    style={{ marginBottom: 20, width: '100%' }}
                    value={schoolId}
                    onSelect={(e: number) => {
                      handleSelectionChange(e);
                    }}
                    options={dropdownOptions}
                    loading={isLoadingSchoolsInZip}
                  />
                </SelectWrapper>
              )}

              {zipCode && (
                <Button type="linkStyle" onClick={handleCantFindMySchool}>
                  I can&apos;t find my school
                </Button>
              )}
            </SchoolSelectionContainer>
          </>
        )}

        <Button
          disabled={getContinueButtonDisabled()}
          onClick={() => {
            handleContinue();
          }}
        >
          Continue
        </Button>
      </FormContainer>
    </BaseModal>
  );
};

export default CountrySchoolSelectionPopup;

const FormContainer = styled.div`
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
`;

const SchoolSelectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-height: 150px;
  width: 100%;
  max-width: 400px;

  @media (max-width: 700px) {
    gap: 30px;
  }
`;
